
:root {
  --theme-color: blue;
}

html, body {
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: 'hidden'
}

/* No other way to do this :/ */
.rv-sticky-tree {
  overflow-x: hidden !important;
}
